<template>
    <div class="content">
        <el-form inline class="tb-form" label-width="90px">
            <el-form-item label="赞助商：">
                <el-select v-model="search.brand_id" class="section-style">
                    <el-option label="全部" :value="null"/>
                    <el-option v-for="item in brandList"
                               :label="item.brandName"
                               :key="item.id"
                               :value="item.id"/>
                </el-select>
            </el-form-item>
            <el-form-item label="开奖时间：">
                <el-date-picker
                        class="section-style"
                        v-model="search.open_date"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        :clearable="false"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="状态：">
                <el-select v-model="search.status" class="section-style">
                    <el-option label="全部" :value="null"/>
                    <el-option label="已开始" :value="1"/>
                    <el-option label="已下架" :value="0"/>
                    <el-option label="已开奖" :value="2"/>
                </el-select>
            </el-form-item>
            <el-form-item label="创建时间：">
                <el-date-picker
                        class="section-style"
                        v-model="search.create_date"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        :clearable="false"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="奖品内容：">
                <el-input v-model="search.gift_name" class="section-style"/>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSearch">查询</el-button>
                <el-button @click="resetParams">重置</el-button>
            </el-form-item>
        </el-form>
        <div class="add">
            <el-button
                    icon="el-icon-plus"
                    type="primary"
                    @click="add">
                发起抽奖
            </el-button>
        </div>
        <div class="tablebox">
            <el-table
                    :data="tableData"
                    @row-dblclick="toDetail"
                    class="tb-table">
                <el-table-column label="赞助商" prop="brand_name"/>
                <el-table-column label="创建时间" width="164px" prop="created_at"/>
                <el-table-column label="开奖时间" width="164px" prop="open_time"/>
                <el-table-column label="奖品内容" min-width="160px">
                    <template slot-scope="scope">
                        <el-popover
                                width="320"
                                placement="top"
                                trigger="hover"
                                :content="scope.row.awards">
                            <div slot="reference" class="ellipsis-one">{{scope.row.awards}}</div>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column label="当前参与人数" prop="join_count" width="120px"/>
                <el-table-column label="上下架状态" width="120px">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status===0">下架</span>
                        <span v-else>上架</span>
                    </template>
                </el-table-column>
                <el-table-column label="开奖状态" width="120px">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status===2">已开奖</span>
                        <span v-else>已开始</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="200px">
                    <template slot-scope="scope">
                        <el-button type="text" @click="editLottery(scope.row.id)">
                            <span v-if="scope.row.status===2">查看</span>
                            <span v-else>编辑</span></el-button>
                        <el-button v-if="scope.row.status===2"
                                   type="text"
                                   @click="showLuckyDialog(scope.row.id)">中奖名单
                        </el-button>
                        <el-button type="text" @click="showAttendSearch(scope.row.id)">参与名单</el-button>
                        <el-button v-if="scope.row.status===1" type="text" @click="offlineLottery(scope.row.id)">下架
                        </el-button>
                        <el-button v-if="scope.row.status===0" type="text" @click="onlineLottery(scope.row.id)">上架
                        </el-button>
                    </template>
                </el-table-column>

            </el-table>
            <el-pagination
                    background
                    class="tb-pagination"
                    layout="prev, pager, next"
                    :current-page.sync="page.no + 1"
                    :page-size="page.size"
                    :total="page.total"
                    @current-change="pageChange"
            />
        </div>
        <el-dialog title="参与名单" :visible.sync="attendDialogVisible">
            <template>
                <el-form inline label-width="70px">
                    <el-form-item label="昵称：">
                        <el-input v-model="attendSearch.nick_name"/>
                    </el-form-item>
                    <el-form-item label="手机号：">
                        <el-input v-model="attendSearch.phone"/>
                    </el-form-item>
                    <el-form-item label="抽奖码：">
                        <el-input v-model="attendSearch.sn"/>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSearchAttend">查询</el-button>
                    </el-form-item>
                </el-form>
                <div class="tablebox">
                    <el-table class="tb-table"
                              :data="attendList">
                        <el-table-column label="昵称" prop="nick_name"/>
                        <el-table-column label="手机号" prop="phone"/>
                        <el-table-column label="抽奖码数量" prop="total_count"/>
                        <el-table-column label="关联抽奖码" prop="sn"/>
                    </el-table>
                    <el-pagination
                            background
                            class="tb-pagination"
                            layout="prev, pager, next"
                            :current-page.sync="attendPage.no + 1"
                            :page-size="attendPage.size"
                            :total="attendPage.total"
                            @current-change="attendPageChange"/>
                </div>
            </template>
        </el-dialog>
        <el-dialog title="中奖名单" :visible.sync="luckyDialogVisible">
            <div class="tablebox">
                <el-table class="tb-table"
                          :data="luckyList">
                    <el-table-column label="昵称" prop="nick_name"/>
                    <el-table-column label="手机号" prop="phone"/>
                    <el-table-column label="中奖码" prop="sn"/>
                    <el-table-column label="状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.status===2">已领奖</span>
                            <span v-else>未领取</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="收件人" prop="contact_name"/>
                    <el-table-column label="联系方式" prop="contact"/>
                    <el-table-column label="收货地址" prop="address" :show-overflow-tooltip="true"/>
                    <el-table-column label="中奖商品" prop="gift_name" :show-overflow-tooltip="true"/>
                </el-table>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "LotteryList",
        data() {
            return {
                search: {
                    brand_id: null,
                    gift_name: '',
                    create_date: ["", ""],
                    start_time: "",
                    end_time: "",
                    open_date: ["", ""],
                    open_start_time: "",
                    open_end_time: "",
                    status: null,
                },
                tableData: [],
                page: {
                    no: 0,
                    size: 10,
                    total: 0
                },
                brandList: [],
                attendDialogVisible: false,
                attendSearch: {
                    id: "",
                    nick_name: "",
                    phone: "",
                    sn: ""
                },
                attendPage: {
                    no: 0,
                    size: 10,
                    total: 0
                },
                attendList: [],
                luckyDialogVisible: false,
                luckyList: [],
                luckySearch: {
                    id: ""
                },
            }
        },
        mounted() {
            let path = this.$route.path
            if (path.indexOf('/') === 0) path = path.substring(1)
            const currentPage = {
                link: path,
                name: '抽奖列表'
            }
            this.$store.commit('changeRoute', currentPage)
            this.getBrandList()
            this.getLotteryList()
        },
        methods: {
            async getBrandList() {
                const url = "admin/home/brand/list"
                const data = await this.$https.get(url)
                if (data == null) return
                this.brandList = data
            },
            add() {
                this.$router.push('/lottery/add')
            },
            pageChange(no) {
                this.page.no = no - 1
                this.getLotteryList()
            },
            onSearch() {
                this.page.no = 0
                this.search.start_time = this.search.create_date[0]
                this.search.end_time = this.search.create_date[1]
                this.search.open_start_time = this.search.open_date[0]
                this.search.open_end_time = this.search.open_date[1]
                this.getLotteryList()
            },
            resetParams() {
                this.search = {
                    brand_id: null,
                    gift_name: '',
                    create_date: ["", ""],
                    open_date: ["", ""],
                    status: null,
                }
            },
            async getLotteryList() {
                const url = "admin/lottery/list"
                const params = {
                    page_no: this.page.no,
                    page_size: this.page.size,
                    ...this.search
                }
                let data = await this.$https.get(url, {params})
                if (data) {
                    this.tableData = data.list
                    this.page.total = data.total_count
                }
            },
            showAttendSearch(id) {
                this.attendSearch.id = id
                this.attendDialogVisible = true
                this.onSearchAttend()
            },
            onSearchAttend() {
                this.attendPage.no = 0
                this.getAttendList()
            },
            async getAttendList() {
                const url = "admin/lottery/user/list"
                let params = {
                    page_no: this.attendPage.no,
                    page_size: this.attendPage.size,
                    ...this.attendSearch
                }
                let data = await this.$https.get(url, {params})
                if (data) {
                    this.attendList = data.list
                    this.attendPage.total = data.total_count
                }
            },
            attendPageChange(no) {
                this.attendPage.no = no - 1
                this.getAttendList()
            },
            showLuckyDialog(id) {
                this.luckySearch.id = id
                this.luckyDialogVisible = true
                this.getLuckyList()
            },
            async getLuckyList() {
                const url = "admin/lottery/user/lucky/list"
                let params = {
                    id: this.luckySearch.id
                }
                let data = await this.$https.get(url, {params})
                if (data) this.luckyList = data
            },
            toDetail(row) {
                this.editLottery(row.id)
            },
            editLottery(id) {
                this.$router.push(`/lottery/add?id=${id}`)
            },
            async offlineLottery(id) {
                this.$confirm("活动下架后，用户所参与的记录将清空，请您谨慎操作！", "确认要下架抽奖活动吗？",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                        showClose: false
                    }).then(async () => {
                    const url = "admin/lottery/edit"
                    let params = {
                        id: id,
                        status: 0
                    }
                    let data = await this.$https.post(url, params)
                    if (data) {
                        this.$message.success("下架成功")
                        await this.getLotteryList()
                    }
                })
            },
            async onlineLottery(id) {
                const url = "admin/lottery/edit"
                let params = {
                    id: id,
                    status: 1
                }
                let data = await this.$https.post(url, params)
                if (data) {
                    this.$message.success("上架成功")
                    await this.getLotteryList()
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .page {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .section-style {
        width: 272px;
    }
</style>